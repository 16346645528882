import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { formatDate, usePerspectiveHover, ownsFeature } from 'utils'

export default function ProductTile({ registration, type, isSupport }) {
	const [availableFeatures, setAvailableFeatures] = useState(0)

	const {
		ex1Layer,
		onMouseEnter,
		onMouseLeave,
		onMouseMove
	} = usePerspectiveHover()

	const {
		id,
		created_at,
		user_id,
		product,
		serial_number,
		date_of_purchase,
		did,
		aid
	} = registration

	useEffect(() => {
		if (registration.product) {
			const features = registration.product.features
			const feature_instances = registration.feature_instances

			const _features = features ? features.filter(feature => {
				if (!aid && feature.price_usd) {
					return false
				}
				return true
			}) : []

			let _availableFeatures = 0

			if (_features.length) {
				_features.map((featureItem) => {
					if(!featureItem.price_usd){
						return
					}

					const alreadyOwns = ownsFeature(feature_instances, featureItem, registration.product)

					if (!alreadyOwns) {
						_availableFeatures++
					}
				})
			}

			setAvailableFeatures(_availableFeatures)
		}

	}, [registration])


	if (!product) {
		return null
	}

	return (
		<div
			onMouseMove={onMouseMove}
			onMouseLeave={onMouseLeave}
			onMouseEnter={onMouseEnter}
			className={'productTile-parent'}
		>
			<div className='productTile' ref={ref => (ex1Layer.current = ref)}>
				<Link exact='true' to={isSupport ? '' + user_id + '/' + id : '/my-products/' + id}>
					<div>
						<div className='productTile-image-cont'>
							<div
								className='productTile-image'
								style={{
									backgroundImage: `url(${'/products/' + product.product_url + '.png'})`
								}}
							/>
						</div>
						<div
							className={`productTile-text`}
						>
							<h4
								dangerouslySetInnerHTML={{
									__html: product.product_name
								}}
							/>
							<div
								className={`productTile-details`}
							>
								{serial_number && <div>S/N: {serial_number}</div>}
								{did && <div>DID: {did}</div>}
								{aid && <div>AID: {aid}</div>}
								<div>
									<span>Purchased on: </span>
									{formatDate(date_of_purchase)}
								</div>
								{isSupport &&
									<div>
										<span>Registered on: </span>
										{formatDate(created_at)}
									</div>
								}
								{availableFeatures ?
									<div
										className="updateAvailable"
									>
										{availableFeatures + ` new feature${availableFeatures === 1 ? '' : 's'} available`}
									</div>
									: null}
							</div>
						</div>
					</div>
				</Link>
			</div>
		</div>
	)
}
