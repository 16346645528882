import React, { useMemo, useState } from 'react'
import FeatureTile from "components/featureTile"

import { doRequest, ownsFeature } from 'utils'
import moment from 'moment'

export default function FeaturesPromos({
    user,
    userAccountData,
    registration
}) {
    const [hasDropboxBusiness, setHasDropboxBusiness] = useState(false)
    const [hasDropboxBusinessPlus, setHasDropboxBusinessPlus] = useState(false)

    const hasFramio = (() => {
        const devices = [
            182, // ninja phone
            166, // atomos connect 
            174
        ]

        return userAccountData?.registrations.some(registration => devices.includes(registration.product_id))
    })()

    useMemo(() => {
        (async () => {
            const createdMoment = moment(registration.created_at)

            const isAfterSeptember13 = createdMoment.isAfter(moment('2024-09-13'))

            const isBeforeNovember30 = createdMoment.isBefore(moment('2024-11-30'))

            if (!isAfterSeptember13 || !isBeforeNovember30) {
                return false
            }

            const { data } = await doRequest('post', 'auth/hasAvailableDropboxUrls')

            if (!data) {
                return false
            }

            setHasDropboxBusiness(data.hasAvailableBusiness)
            setHasDropboxBusinessPlus(data.hasAvailableBusinessPlus)
        })()
    }, [])

    const promoFeatures = []

    if (hasFramio) {
        promoFeatures.push({
            name: '3 months free frame.io',
            brief_description: 'You’re eligible for a 3-month extended trial of frame.io V4.',
            image: '/images/frameio-thumb.jpg',
            id: -2,
            url: 'https://www.adobe.com/go/atomosc2c',
            buyLabel: `Find out more`
        })
    }

    if (hasDropboxBusiness || hasDropboxBusinessPlus) {
        promoFeatures.push({
            name: '40% off Dropbox Annual Plan',
            brief_description: 'For a limited period only, you are eligible for 40% off a 12-month Business or Business Plus Dropbox plan.',
            image: '/images/dropbox-thumb.jpg',
            id: -3,
            url: `https://api.my.atomos.com/auth/generateDropboxUrl?user_id=${userAccountData?.id}&type=business`,
            buyLabel: `Get Dropbox Business`,
            hideButton1: !hasDropboxBusiness,
            isDropbox: true,
            url2: `https://api.my.atomos.com/auth/generateDropboxUrl?user_id=${userAccountData?.id}&type=plus`,
            buyLabel2: `Get Dropbox Business Plus`,
            hideButton2: !hasDropboxBusinessPlus,
        })
    }

    if (!promoFeatures.length) {
        return null
    }

    return (
        <div className='singleRegistration-promotions'>
            <h5>
                Promotions
            </h5>
            <ul>
                {promoFeatures.map((featureItem, index) => {
                    const alreadyOwns = ownsFeature([], featureItem, null, userAccountData)

                    return (
                        <li key={index}>
                            <FeatureTile
                                owns={alreadyOwns}
                                feature={featureItem}
                                registration={registration}
                                canActivate
                                user={user}
                            />
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}